import { MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Entity } from '@mpe/api-client/models/entities/entity'
import urlHelper from '@mpe/api-client/utils/urlHelper'

import Icon from '../Icon'
import Image from '../Image'
import ImageFallback from './ImageFallback'

import { classes } from './Cover'
import { show } from '@/store/slices/modalSlice'
import {
  favoritesSelector,
  isEntityFavoritedSelector,
  toggleFavorite
} from '@/store/slices/favoritesSlice'
import { authSelector } from '@/store/slices/authSlice'
import { getEntityType, getRoleLabels, getTypeIcon, getTypeString } from '@/utils'

import coverStyles from './coverStyles.module.css'
import { Link } from '../Link/Link'
import { useRouter } from 'next/router'
import AudioPlaylistModal from '../AudioPlaylistModal/AudioPlaylistModal'

interface DefaultCoverHeaderProps {
  bgGradientClasses: string
  entity: Entity<any>
}

const DefaultCoverHeader = ({ entity, bgGradientClasses }: DefaultCoverHeaderProps) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const lang = router.query.lang
  const isAuth = useSelector(authSelector).isAuth
  const isFavoritesLoading = useSelector(favoritesSelector).isLoading
  const isFavorited = useSelector(isEntityFavoritedSelector(entity['@id']))

  const { name, coverImageUrl, role } = entity
  const type = getEntityType(entity)
  const isImage = 'Image' === type
  const iconName = getTypeIcon(String(type))

  const fortepanImageSource = entity.fortepanCoverImageSource
  const coverImageSource = entity.coverImageSource
  const mtvaImageHash = entity.coverMtvaImageUrl
  const mtvaImageUrl = mtvaImageHash ? urlHelper.getMtvaUrl(mtvaImageHash, 'lg') : null
  const mtvaImagePosition = entity.coverMtvaImagePosition
  let defaultImageUrl = isImage ? entity.fileName : entity.defaultImageUrl

  if (!defaultImageUrl) {
    defaultImageUrl = entity['squareImageUrl'] ?? ''
  }

  const onFavoriteClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()

    if (isFavoritesLoading) {
      return
    }

    if ('Audio' !== entity.type) {
      dispatch(toggleFavorite(entity['@id']))
      return
    }

    dispatch(show({ modalComponent: <AudioPlaylistModal entity={entity} /> }))
  }

  return (
    <header
      className={`relative ${isImage ? '' : 'bg-gradient-to-tr'} ${
        isImage ? coverStyles.coverWrapperImage : coverStyles.coverWrapper
      } ${bgGradientClasses}`}
    >
      <div className="absolute w-full h-full">
        <ImageFallback entryType={String(type)} className="w-6 h-6 md:w-24 md:h-24" />
      </div>

      {coverImageUrl && (
        <Image src={coverImageUrl} className={classes.image} priority contain={isImage} />
      )}
      {!coverImageUrl && mtvaImageUrl && (
        <Image
          src={mtvaImageUrl}
          className={classes.image}
          position={mtvaImagePosition}
          useSrcLoader
        />
      )}
      {!coverImageUrl && !mtvaImageUrl && defaultImageUrl && (
        <div>
          <Image src={defaultImageUrl} className="z-1" position="center center" />
          <Image src={defaultImageUrl} className="z-10 backdrop-filter backdrop-blur" contain />
        </div>
      )}

      <div className={`${classes.imageOverlay} z-20`} />

      <div className={`${classes.textContainer} z-30`}>
        <div className={classes.textEyebrowContainer}>
          {type ? (
            <>
              <Icon name={iconName} className={classes.textEyebrowIcon} />
              <span className={classes.textEyebrow}>
                {lang === 'eng' ? type : getTypeString(String(type))}
              </span>
            </>
          ) : null}
        </div>
        {!isImage && <h3 className={classes.textTitle}>{name}</h3>}
        {role && <h4 className="mb-4 text-xs md:mb-8 md:text-lg">{getRoleLabels(role || null)}</h4>}

        {!coverImageUrl && mtvaImageUrl && (
          <div className="my-1 lg:my-4">
            Borítókép forrása:{' '}
            <Link
              href={`https://archivum.mtva.hu/photobank/item/${mtvaImageHash}`}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              MTVA, Nemzeti Fotótár
            </Link>
          </div>
        )}

        {coverImageUrl && fortepanImageSource && fortepanImageSource !== 'null' && (
          <div className="my-1 lg:my-4">Borítókép forrása: {fortepanImageSource}</div>
        )}

        {coverImageUrl && coverImageSource && coverImageSource !== 'null' && (
          <div className="my-1 lg:my-4">Borítókép forrása: {coverImageSource}</div>
        )}

        {entity.mediaSource && entity.mediaSource !== 'null' && (
          <div className="my-1 lg:my-4">Forrás: {entity.mediaSource}</div>
        )}
      </div>
      {isAuth && (
        <div className="absolute bottom-0 right-0 p-6 z-40">
          <Icon
            name="star"
            className="w-10 cursor-pointer"
            fill={isFavorited}
            onClick={onFavoriteClick}
          />
        </div>
      )}
    </header>
  )
}

export default DefaultCoverHeader

import React, { useRef, useEffect, useState } from 'react'
import Icon from '../Icon'
import { formatDuration, imageWithWidth } from '@/utils'
import { Audio } from '@mpe/api-client/models/entities/audio'
import apiClient from '@mpe/api-client'
import Image from '../Image'

interface SingleAudioPlayerProps {
  entity: Audio
  isPlaying: boolean
  setIsPlaying: (isPlaying: boolean) => void
  showSingleAudioPlayer: boolean
  setShowSingleAudioPlayer: (showSingleAudioPlayer: boolean) => void
  autoPlay?: boolean
}

const SingleAudioPlayer = ({
  entity,
  isPlaying,
  setIsPlaying,
  showSingleAudioPlayer,
  setShowSingleAudioPlayer,
  autoPlay = false
}: SingleAudioPlayerProps) => {
  const audioRef = useRef<HTMLAudioElement | null>(null)

  const [duration, setDuration] = useState(0)
  const [currentTrackPosition, setCurrentTrackPosition] = useState(0)

  const name = entity.name
  const src = apiClient.getConfig().s3Url + entity.fileName

  useEffect(() => {
    if (audioRef.current) {
      try {
        if (!isPlaying && !audioRef.current.paused) {
          audioRef.current.pause()
        }
        if (isPlaying && audioRef.current.paused) {
          audioRef.current.play()
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [isPlaying])

  const onPlayPauseClick = () => {
    setIsPlaying(!isPlaying)
  }

  const onCancelClick = () => {
    setIsPlaying(false)
    restartAudio()
    setShowSingleAudioPlayer(false)
  }

  const onTimeUpdate = function (this: HTMLAudioElement) {
    setCurrentTrackPosition(Math.floor(this.currentTime))
  }

  const onDurationChange = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration)
    }
  }

  const restartAudio = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0
      setCurrentTrackPosition(0)
    }
  }

  useEffect(() => {
    const audioElement = audioRef.current
    if (audioElement) {
      audioElement.src = src
      audioElement.addEventListener('timeupdate', onTimeUpdate)
      audioElement.addEventListener('durationchange', onDurationChange)
      if (autoPlay) {
        audioElement.play()
        setIsPlaying(true)
      }

      return () => {
        audioElement.removeEventListener('timeupdate', onTimeUpdate)
        audioElement.removeEventListener('durationchange', onDurationChange)
      }
    }
  }, [src])

  return (
    <div
      className={`w-full z-30 fixed bottom-0 left-0 py-2 flex items-center px-1 lg:px-4 h-24 bg-mostlyBlack ${
        !showSingleAudioPlayer && 'hidden'
      }`}
    >
      <audio ref={audioRef} style={{ display: 'none' }} />
      <div className="flex items-center w-full h-full relative container-padded">
        <div className="w-14 h-14 relative mr-2 border border-white">
          {entity.defaultImageUrl ? (
            <Image useSrcLoader src={imageWithWidth(entity.defaultImageUrl, 80)} />
          ) : (
            <div className="flex w-full h-full items-center justify-center bg-gradient-to-tr from-tomato to-indigo">
              <Icon name="song" className="w-4 h-4" disableHover />
            </div>
          )}
        </div>
        <div className="overflow-hidden text-ellipsis z-50 max-w-xs">
          <span className="block font-bold mb-1 truncate">{name}</span>
        </div>
        <div className="lg:absolute lg:w-full flex items-end lg:items-center flex-col z-40 ml-auto lg:ml-0">
          <div className="flex items-center">
            <div className="flex bg-gradient-to-tr from-tomato to-indigo w-6 h-6 rounded-full items-center justify-center mr-2">
              <Icon
                name="rotate-left"
                className="w-3 h-3 transform flip-180 fill-white"
                onClick={restartAudio}
              />
            </div>
            <div className="bg-gradient-to-tr from-tomato to-indigo w-10 h-10 rounded-full flex items-center justify-center cursor-pointer">
              <Icon
                name={`${isPlaying ? 'pause' : 'play'}`}
                className={`w-5 h-5 ${isPlaying ? '' : 'ml-1'}`}
                onClick={onPlayPauseClick}
              />
            </div>
          </div>
          <div className="absolute -bottom-1 left-0 right-0 lg:relative flex items-center mt-1">
            <div className="hidden lg:flex text-sm w-12 justify-center">
              {formatDuration(currentTrackPosition)}
            </div>
            <div className="w-full lg:w-96 h-0.5 bg-darkGray">
              <div
                className="h-full bg-gradient-to-r from-tomato to-indigo transition-all"
                style={{
                  width: `${(currentTrackPosition / duration) * 100}%`
                }}
              />
            </div>
            <div className="hidden lg:flex text-sm w-12 justify-center">
              {formatDuration(duration)}
            </div>
          </div>
        </div>
        <div className="absolute -top-4 left-0 lg:top-auto lg:left-auto lg:relative ml-auto w-6 h-6 cursor-pointer z-50 bg-mostlyBlack flex items-center justify-center rounded-full">
          <Icon name="cancel" className="w-3 h-3 lg:w-4 lg:h-4" onClick={onCancelClick} />
        </div>
      </div>
    </div>
  )
}

export default SingleAudioPlayer

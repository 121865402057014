import Plyr from 'plyr'
import { Connection } from '@mpe/api-client/models/connections/connection'
import { Entity } from '@mpe/api-client/models/entities/entity'
import { Video as VideoEntity } from '@mpe/api-client/models/entities/video'
import { Audio as AudioEntity } from '@mpe/api-client/models/entities/audio'

import { getEntityType } from '@/utils'

import DefaultCoverHeader from './DefaultCoverHeader'
import AudioCoverHeader from './AudioCoverHeader'
import AlbumCoverHeader from './AlbumCoverHeader'
import VideoCoverHeader from './VideoCoverHeader'
import { gradients } from '../Tiles/consts'

export const classes = {
  header: 'flex justify-center bg-gradient-to-tr',
  headerInner: 'w-full lg:w-2/3 xl:w-7/12',
  gallery: 'absolute w-full h-full bg-black bg-opacity-80',
  imageOverlay:
    'absolute w-full h-1/4 top-0 bg-gradient-to-b from-black via-transparent to-transparent',
  image: 'object-cover object-center',
  textContainer: `absolute bottom-0 text-white px-4 lg:px-14 py-2 lg:py-6 w-full bg-gradient-to-t from-black to-transparent`,
  textContainerVideo: `flex p-4 md:p-10 text-white`,
  textEyebrowContainer: 'flex flex-row items-center',
  textEyebrowIcon: 'w-4 mr-3 text-white',
  textEyebrow: 'text-sm mr-3',
  textTitle: 'mt-3 md:mt-5 text-xl lg:text-5xl font-bold tracking-wide',
  dateFrom: 'text-lg lg:text-3xl'
}

interface CoverProps {
  entity: Entity<any>
  mediaConnections: Connection[]
  setVideoPlayer: (player: Plyr) => void
}

export default function Cover({ entity, mediaConnections, setVideoPlayer }: CoverProps) {
  const type = getEntityType(entity)
  const bgGradientClasses = gradients[type] || gradients.default

  if (type === 'Album' && mediaConnections.length) {
    return <AlbumCoverHeader entity={entity} mediaConnections={mediaConnections} />
  }

  if (entity instanceof VideoEntity) {
    return <VideoCoverHeader entity={entity as any} setVideoPlayer={setVideoPlayer} />
  }

  if (entity instanceof AudioEntity) {
    return <AudioCoverHeader entity={entity as any} bgGradientClasses={bgGradientClasses} />
  }

  return <DefaultCoverHeader bgGradientClasses={bgGradientClasses} entity={entity} />
}

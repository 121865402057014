import { useCallback, useEffect, useState } from 'react'
import { useEmblaCarousel } from 'embla-carousel/react'
import { Image as ImageEntity } from '@mpe/api-client/models/entities/image'
import { Connection } from '@mpe/api-client/models/connections/connection'
import { Entity } from '@mpe/api-client/models/entities/entity'

import Image from '../Image'
import Icon from '../Icon'
import MediaGalleryThumb from './MediaGalleryThumb'

import useDataLayer from '@/hooks/useDataLayer'

import mediaGalleryStyles from './MediaGallery.module.css'
import coverStyles from '../Cover/coverStyles.module.css'

interface MediaGalleryProps {
  mediaConnections: Connection[]
  className?: string
}

export default function MediaGallery({ mediaConnections, className }: MediaGalleryProps) {
  const [activeImageIndex, setActiveImageIndex] = useState(0)
  const [emblaRef, emblaApi] = useEmblaCarousel({
    speed: 17,
    skipSnaps: true,
    draggable: true
  })
  const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({})
  const dataLayer = useDataLayer()

  const classes = {
    wrapper: (scrollDisabled?: boolean) =>
      `${mediaGalleryStyles.hoverArea} ${className} ${scrollDisabled ? 'disabled ' : ''}`,
    arrow: `${mediaGalleryStyles.scrollButton} bg-black bg-opacity-25 px-5 py-1 cursor-pointer`
  }

  const pushImageViewEvent = (imageSource: string) => {
    dataLayer.push({
      event: 'view-gallery-image',
      imageSource
    })
  }

  const scrollPrev = useCallback(() => {
    if (emblaApi) {
      const nextIndex = activeImageIndex - 1
      if (nextIndex < 0) {
        return
      }
      setActiveImageIndex(nextIndex)
    }
  }, [emblaApi, mediaConnections, activeImageIndex])

  const scrollNext = useCallback(() => {
    if (emblaApi) {
      const nextIndex = activeImageIndex + 1
      if (nextIndex > mediaConnections.length - 1) {
        return
      }
      setActiveImageIndex(nextIndex)
    }
  }, [emblaApi, mediaConnections, activeImageIndex])

  const onThumbClick = (index: number) => setActiveImageIndex(index)

  const onSelect = useCallback(() => {
    if (emblaApi) {
      setActiveImageIndex(emblaApi.selectedScrollSnap())
    }
  }, [emblaApi, setActiveImageIndex])

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('reInit', onSelect)
      emblaApi.on('select', onSelect)
    }
  }, [emblaApi, mediaConnections, onSelect])

  useEffect(() => {
    if (!mediaConnections[activeImageIndex]?.toEntity || !emblaApi || !emblaThumbs) {
      return
    }

    const entity = mediaConnections[activeImageIndex].toEntity
    pushImageViewEvent(entity.fileName as string)

    emblaThumbs.scrollTo(activeImageIndex)
    emblaApi.scrollTo(activeImageIndex)
  }, [activeImageIndex, mediaConnections, emblaThumbs, emblaApi])

  return (
    <div className={`flex relative ${coverStyles.coverMediaGallery}`}>
      <div
        className={`flex flex-col overflow-hidden ${classes.wrapper(mediaConnections.length < 2)}`}
      >
        <div className="embla h-full">
          <div className="embla__viewport h-full" ref={emblaRef}>
            <div className="embla__container flex h-full">
              {mediaConnections
                .filter((conn) => conn.toEntity instanceof ImageEntity)
                .map((conn, index) => {
                  const image: Entity<ImageEntity> = conn.toEntity
                  return (
                    <div
                      key={image['@id']}
                      className="embla__slide relative flex-none w-2/3 mx-1 lg:mx-0 lg:w-full lg:h-full"
                    >
                      {image.fileName &&
                        // load one to the left and right as well because of mobile view
                        [activeImageIndex - 1, activeImageIndex, activeImageIndex + 1].includes(
                          index
                        ) && (
                          <div>
                            <Image
                              src={image.fileName}
                              className="z-10"
                              mediaSource={image.mediaSource}
                              showLoader
                              contain
                              position="center center"
                            />
                            <Image src={image.fileName} className="z-1 filter opacity-20" />
                          </div>
                        )}
                    </div>
                  )
                })}
            </div>
          </div>
        </div>

        <div
          onClick={scrollPrev}
          className="hidden lg:flex absolute top-0 left-0 h-full items-center"
        >
          <div className={classes.arrow}>
            <Icon name="arrow-left" className="w-6 h-6 md:w-24 md:h-24 grow-0" disableHover />
          </div>
        </div>
        <div
          onClick={scrollNext}
          className="hidden lg:flex absolute top-0 right-0 h-full items-center"
        >
          <div className={classes.arrow}>
            <Icon name="arrow-right" className="w-6 h-6 md:w-24 md:h-24 grow-0" disableHover />
          </div>
        </div>

        <div className="hidden lg:flex embla embla--thumb mt-2">
          <div className="embla__viewport flex h-full w-full" ref={thumbViewportRef}>
            <div className="embla__container embla__container--thumb flex h-full w-full">
              {mediaConnections
                .filter((conn) => conn.toEntity instanceof ImageEntity)
                .map((conn, index) => {
                  const image: Entity<ImageEntity> = conn.toEntity
                  return (
                    <MediaGalleryThumb
                      onClick={() => onThumbClick(index)}
                      selected={index === activeImageIndex}
                      imgSrc={image.fileName}
                      key={index}
                    />
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

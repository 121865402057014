import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Audio } from '@mpe/api-client/models/entities/audio'
import { AudioPlaylist } from '@mpe/api-client/models/audio-playlist'
import { AudioTrack } from '@mpe/api-client/models/audio-track'

import Icon from '../Icon'
import Image from '../Image'

import { authSelector } from '@/store/slices/authSlice'
import { show } from '@/store/slices/modalSlice'
import { audioPlaylistsSelector } from '@/store/slices/audioPlaylistsSlice'
import {
  createAudioPlaylist,
  getAudioPlaylists,
  updateAudioTracksOrder
} from '@/store/thunks/audioPlaylistsThunk'
import { createTrack } from '@/store/slices/tracksSlice'
import { AppThunk } from '@/store/index'
import { getEntityType, getTypeIcon, getTypeString } from '@/utils'
import { DEFAULT_PLAYLIST } from '@/consts'
import useDataLayer from '@/hooks/useDataLayer'

import { classes } from './Cover'
import coverStyles from './coverStyles.module.css'
import { setSelectedPlaylist } from '@/store/slices/audioPlayerSlice'
import AudioPlaylistModal from '../AudioPlaylistModal/AudioPlaylistModal'
import SingleAudioPlayer from '../SingleAudioPlayer'

interface AudioVideoCoverHeaderProps {
  entity: Audio
  bgGradientClasses: string
}

const AudioVideoCoverHeader = ({ entity, bgGradientClasses }: AudioVideoCoverHeaderProps) => {
  const dispatchAsync = useDispatch<(thunk: AppThunk) => Promise<any>>()
  const dispatch = useDispatch()

  const dataLayer = useDataLayer()

  const { isAuth } = useSelector(authSelector)

  const { name } = entity
  const type = getEntityType(entity)
  const iconName = getTypeIcon(String(type))

  // Single audio player for not logged in users
  const [showSingleAudioPlayer, setShowSingleAudioPlayer] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)

  const selectDefaultPlaylist = async (playlists: any): Promise<AudioPlaylist | undefined> =>
    (playlists || []).find((i: AudioPlaylist) => i.name === DEFAULT_PLAYLIST)

  const playDefaultPlaylist = async (): Promise<void> => {
    const playlists = await dispatchAsync(getAudioPlaylists())
    const toBePlay = playlists.find((i: AudioPlaylist) => i.name === DEFAULT_PLAYLIST)

    if (toBePlay && toBePlay.tracks?.length) {
      dispatch(
        setSelectedPlaylist({
          item: toBePlay,
          trackId: toBePlay.tracks[0]['@id'],
          trackPosition: 0,
          isPlaying: true
        })
      )
      dataLayer.push({
        event: 'play-audio-auth',
        entityId: entity.id,
        title: entity.name
      })
    }
  }

  const playGlobalAudio = async () => {
    const playlists = await dispatchAsync(getAudioPlaylists())
    const defaultPlaylist = await selectDefaultPlaylist(playlists)

    if (!defaultPlaylist) {
      const { playlist } = await dispatchAsync(createAudioPlaylist(DEFAULT_PLAYLIST))
      await dispatchAsync(createTrack(playlist['@id'], entity['@id']))
    } else {
      const alreadyOnList = defaultPlaylist.tracks.find(
        (t: AudioTrack) => t.audio['@id'] === entity['@id']
      )

      if (!alreadyOnList) {
        const track = await dispatchAsync(createTrack(defaultPlaylist['@id'], entity['@id']))
        await dispatchAsync(
          updateAudioTracksOrder([track.id, ...defaultPlaylist.tracks.map((t) => t.id)])
        )
      } else {
        await dispatchAsync(
          updateAudioTracksOrder([
            alreadyOnList.id,
            ...defaultPlaylist.tracks
              .filter((t) => t['@id'] !== alreadyOnList['@id'])
              .map((t) => t.id)
          ])
        )
      }
    }

    playDefaultPlaylist()
  }

  const onPlayIconClick = async () => {
    if (isAuth) {
      setIsPlaying(false)
      playGlobalAudio()
    } else {
      dispatch(setSelectedPlaylist(null))
      setShowSingleAudioPlayer(true)
      setIsPlaying(true)
    }
  }

  return (
    <>
      <header className={`${classes.header} relative`}>
        <div className="w-full">
          <div className={`relative ${coverStyles.coverWrapper} overflow-hidden`}>
            {entity.defaultImageUrl ? (
              <div className="absolute w-full h-full filter blur-sm z-10">
                <Image src={entity.defaultImageUrl} className={`object-cover object-center`} />
              </div>
            ) : (
              <div
                className={`absolute w-full h-full bg-gradient-to-tr ${bgGradientClasses} z-10`}
              />
            )}
            <div className={`${classes.imageOverlay} z-20`} />
            <div className="absolute w-full h-full flex justify-center items-center z-30">
              {isPlaying ? (
                <Icon
                  name="pause-circle"
                  className="w-32 h-32 cursor-pointer"
                  disableHover
                  onClick={() => setIsPlaying(false)}
                />
              ) : (
                <Icon
                  name="play-circle"
                  className="w-32 h-32 cursor-pointer"
                  onClick={() => onPlayIconClick()}
                  disableHover
                />
              )}
            </div>
          </div>
        </div>
      </header>
      <div className={classes.textContainerVideo}>
        <div>
          <div className={classes.textEyebrowContainer}>
            <Icon name={iconName} className={classes.textEyebrowIcon} />
            <span className={classes.textEyebrow}>{getTypeString(String(type))}</span>
          </div>
          <div>
            <h3 className={classes.textTitle}>{name}</h3>
            {entity.mediaSource && entity.mediaSource !== 'null' && (
              <div className="mb-4 mt-4 opacity-70">Forrás: {entity.mediaSource}</div>
            )}
          </div>
        </div>
        <div className="ml-auto flex items-center">
          {isAuth && (
            <Icon
              name="add-to-playlist"
              className="w-10 cursor-pointer"
              onClick={() =>
                dispatch(show({ modalComponent: <AudioPlaylistModal entity={entity} /> }))
              }
            />
          )}
        </div>
      </div>
      {!isAuth && (
        <SingleAudioPlayer
          entity={entity}
          setIsPlaying={setIsPlaying}
          isPlaying={isPlaying}
          setShowSingleAudioPlayer={setShowSingleAudioPlayer}
          showSingleAudioPlayer={showSingleAudioPlayer}
        />
      )}
    </>
  )
}

export default AudioVideoCoverHeader

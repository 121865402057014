import Image from '../Image'

import { imageWithWidth } from '@/utils'

interface ThumbProps {
  selected: boolean
  onClick: () => void
  imgSrc: string | undefined
}

const Thumb = ({ selected, onClick, imgSrc }: ThumbProps) => {
  if (!imgSrc) {
    return null
  }

  return (
    <div
      className={`embla__slide embla__slide--thumb flex h-full transition-all select-none mr-3 ${
        selected ? 'border-2 border-white' : ''
      }`}
    >
      <button
        onClick={onClick}
        className="embla__slide__inner embla__slide__inner--thumb relative w-20 h-20 overflow-hidden flex"
        type="button"
      >
        <Image
          className="embla__slide__thumbnail"
          src={imageWithWidth(imgSrc, 80)}
          useSrcLoader={true}
        />
      </button>
    </div>
  )
}

export default Thumb

import Icon from '../Icon'

import { getTypeIcon } from '../../utils'

const classes = {
  container: 'h-full flex justify-center items-center',
  icon: 'text-white'
}

interface IImageFallback {
  entryType: string
  className: string
}

export default function ImageFallback({ entryType, className }: IImageFallback) {
  return (
    <div className={classes.container}>
      <Icon name={getTypeIcon(entryType)} className={`${className} ${classes.icon}`} disableHover />
    </div>
  )
}

import { Entity } from '@mpe/api-client/models/entities/entity'
import { Album } from '@mpe/api-client/models/entities/album'
import { Connection } from '@mpe/api-client/models/connections/connection'

import Icon from '../Icon'
import MediaGallery from '../MediaGallery'

import { getEntityType, getTypeIcon, getTypeString } from '@/utils'

import { classes } from './Cover'

interface AudioVideoCoverHeaderProps {
  entity: Entity<Album>
  mediaConnections: Connection[]
}

const AlbumCoverHeader = ({ entity, mediaConnections }: AudioVideoCoverHeaderProps) => {
  const { name } = entity
  const type = getEntityType(entity)
  const iconName = getTypeIcon(String(type))

  return (
    <>
      <header className={classes.header}>
        <div className="w-full">
          <MediaGallery className={classes.gallery} mediaConnections={mediaConnections} />
        </div>
      </header>
      <div className={`${classes.textContainerVideo} flex-col select-none`}>
        <div className={classes.textEyebrowContainer}>
          <Icon name={iconName} className={classes.textEyebrowIcon} />
          <span className={classes.textEyebrow}>{getTypeString(String(type))}</span>
        </div>
        <h3 className={classes.textTitle}>{name}</h3>
      </div>
    </>
  )
}

export default AlbumCoverHeader

import { useDispatch, useSelector } from 'react-redux'
import Plyr from 'plyr'
import { Video } from '@mpe/api-client/models/entities/video'
import { VideoPlaylist } from '@mpe/api-client/models/video-playlist'

import Icon from '../Icon'
import VideoPlayer from '../VideoPlayer'
import VideoPlaylistModal from '../VideoPlaylistModal'

import { formatDateString, getTypeIcon, getTypeString } from '@/utils'
import { show } from '@/store/slices/modalSlice'
import { videoPlaylistsSelector } from '@/store/slices/videoPlaylistsSlice'
import { DEFAULT_VIDEO_PLAYLIST } from '@/consts'
import {
  addVideoPlaylistItem,
  createVideoPlaylist,
  getToken,
  getVideoPlaylists,
  updateVideoPlaylistItemsOrder
} from '@/store/thunks/videoPlaylistsThunk'
import { AppThunk } from '@/store/index'
import { authSelector } from '@/store/slices/authSlice'

import { classes } from '../Cover/Cover'
import { useEffect } from 'react'

interface VideoCoverHeaderProps {
  entity: Video
  setVideoPlayer: (player: Plyr) => void
}

export default function VideoCoverHeader({ entity, setVideoPlayer }: VideoCoverHeaderProps) {
  const dispatch = useDispatch()
  const dispatchAsync = useDispatch<(thunk: AppThunk) => Promise<any>>()

  const { data } = useSelector(videoPlaylistsSelector)

  const { isAuth } = useSelector(authSelector)
  const iconName = getTypeIcon(entity.type)

  const { dateFrom } = entity
  let formattedDateFrom
  if (dateFrom) {
    formattedDateFrom = dateFrom.length > 4 ? formatDateString(dateFrom, false) : dateFrom
  }

  const selectDefaultVideoPlaylist = async (): Promise<VideoPlaylist | undefined> =>
    (data || []).find((p) => p.name === DEFAULT_VIDEO_PLAYLIST)

  const onAddToPlaylist = () =>
    dispatch(show({ modalComponent: <VideoPlaylistModal entity={entity} /> }))

  const addToDefaultPlaylist = async () => {
    if (!isAuth) {
      return
    }

    const defaultVideoPlaylist = await selectDefaultVideoPlaylist()

    if (!defaultVideoPlaylist) {
      const { playlist } = await dispatchAsync(createVideoPlaylist(DEFAULT_VIDEO_PLAYLIST))
      await dispatchAsync(addVideoPlaylistItem(playlist['@id'], entity.id))
    } else {
      const alreadyOnList = defaultVideoPlaylist.tracks.find(
        (pi) => pi.video['@id'] === entity['@id']
      )

      if (!alreadyOnList) {
        await dispatchAsync(addVideoPlaylistItem(defaultVideoPlaylist['@id'], entity.id))

        await dispatchAsync(
          updateVideoPlaylistItemsOrder(defaultVideoPlaylist['@id'], [
            ...defaultVideoPlaylist.tracks.map((i) => String(i.id))
          ])
        )
      } else {
        await dispatchAsync(
          updateVideoPlaylistItemsOrder(defaultVideoPlaylist['@id'], [
            String(alreadyOnList.id),
            ...defaultVideoPlaylist.tracks
              .filter((i) => i['@id'] !== alreadyOnList['@id'])
              .map((i) => String(i.id))
          ])
        )
      }
    }
  }

  useEffect(() => {
    if (getToken(true)) {
      dispatch(getVideoPlaylists())
    }
  }, [])

  return (
    <>
      <header className={classes.header}>
        <VideoPlayer
          entity={entity}
          setVideoPlayer={setVideoPlayer}
          onPlay={addToDefaultPlaylist}
          autoplay={true}
        />
      </header>
      <div className={classes.textContainerVideo}>
        <div>
          <div className={classes.textEyebrowContainer}>
            <Icon name={iconName} className={classes.textEyebrowIcon} />
            <span className={classes.textEyebrow}>{getTypeString(entity.type)}</span>
          </div>
          <div>
            <h3 className={classes.textTitle}>
              {entity.name}
              {formattedDateFrom ? (
                <span className={classes.dateFrom}> ({formattedDateFrom})</span>
              ) : (
                ''
              )}
            </h3>
            {entity.mediaSource && entity.mediaSource !== 'null' && (
              <div className="mb-4 mt-4 opacity-70">Forrás: {entity.mediaSource}</div>
            )}
          </div>
        </div>
        <div className="ml-auto flex items-center">
          {isAuth && (
            <Icon
              name="add-to-video-playlist"
              className="w-6 md:w-12 cursor-pointer"
              onClick={onAddToPlaylist}
            />
          )}
        </div>
      </div>
    </>
  )
}
